import React from 'react';
// import { Typography } from '@material-ui/core';
// import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
// import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
// import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import Grid from '@material-ui/core/Grid';
import TypoBlock from './TypoBlock';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  coords: {
    fontSize: '1rem',
  },
  nodeco: {
    textDecoration: 'none',
    color: '#ffffff',

    "&:hover": {
      color: "#82D2EE",
      textDecoration: 'underline'
    },
  }
}));


export default function Pied() {
  const classes = useStyles();

  return (
    <TypoBlock>
      <Grid
        container 
        direction="row"
        // justify="center"
        alignItems="stretch"
        // spacing={2}
      >
        <Grid item xs={12} sm={6} className={classes.coords}>
          <p>
            Hébergement: <a target='_blank' rel='noreferrer' className={classes.nodeco} href='https://gitlab.com'>gitlab.com</a>
          </p>
          <p>
            Domaine: <a target='_blank' rel='noreferrer' className={classes.nodeco} href='https://ovh.com'>ovh.com</a>
          </p>
          <p>
            SIRET: 90085234400010
          </p>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coords}>
          <p>
            <a className={classes.nodeco} target='_blank' rel='noreferrer' href='https://www.legeste-graphoformations.com/index.php'>Le Geste, Centre de formation</a>
          </p>
        </Grid>
       
      </Grid>
    </TypoBlock>
  );
}
