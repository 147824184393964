// import React from 'react';
import { Typography } from '@material-ui/core';
import TypoBlock from './TypoBlock';

export default function Tarifs() {

  return (
    <TypoBlock>
      <Typography variant='h1'>Services et parcours thérapeutique</Typography>
      <Typography variant='h2'>Bilan graphomoteur</Typography>
      <Typography variant="body1">
        <em>1H30 à 2h: 120 €</em>
      </Typography>
      <Typography variant="body1">
        Il retrace les antécédents familiaux du patient ainsi que les différents traitements, explorations et prises en charges entrepris.<br/>
        Il se compose de plusieurs tests : consultation des cahiers présentés, préhension de l'outil scripteur, vérification de la latéralité principale, dictée d'Ajuriaguerra, test de vitesse et de motricité fine, détermination de l'âge graphomoteur.
      </Typography>
      <Typography variant='h2'>Séance de rééducation</Typography>
      <Typography variant="body1">
        <em>45mn: 50 €</em>
      </Typography>
      <Typography variant="body1">
        A l'issu du bilan, un plan de rééducation personnel est proposé.<br/>
        Les séances sont alors ciblées en fonction des besoins sur la détente du geste graphique, sur de la motricité fine, sur le travail de la préhension de l'instrument scripteur, sur l'optimisation de la posture et de la pression, sur le rythme ou sur le travail du graphisme ou des formes de base.<br/>
        Toute séance débutera sur de la relaxation.<br/>
        (A noter que la durée de la rééducation ne peut pas être déterminée en amont : elle dépendra de l'implication de la personne, de sa régularité et de la participation active du patient et de sa famille)
      </Typography>
    </TypoBlock>
  );
}
