import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: theme.spacing(3),
    fontSize: '1.2rem',
    //maxWidth: '1024px',
    paddingBottom: theme.spacing(3),
  },
}));

export default function Marjolaine() {
  const classes = useStyles();
  return(
    <div className={classes.root}>
      <Typography variant='h1'>Graphothérapeute certifiée</Typography>
      <Typography variant='body1'>
        Spécialisée dans la graphothérapie clinique et pathologique des enfants, adolescents et adultes.
      </Typography>
    </div>
  )
}

