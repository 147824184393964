import React from 'react';
import TypoBlock from './TypoBlock';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import GestureIcon from '@material-ui/icons/Gesture';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
// import BallotIcon from '@material-ui/icons/Ballot';
import BlurOnIcon from '@material-ui/icons/BlurOn';
// import BorderColorIcon from '@material-ui/icons/BorderColor';
// import CategoryIcon from '@material-ui/icons/Category';
// import ClassIcon from '@material-ui/icons/Class';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import EditIcon from '@material-ui/icons/Edit';
// import ErrorIcon from '@material-ui/icons/Error';
// import ExtensionIcon from '@material-ui/icons/Extension';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
// import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
import HelpIcon from '@material-ui/icons/Help';
import HdrOffIcon from '@material-ui/icons/HdrOff';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  icn: {
    marginRight: theme.spacing(1),
  }
}));

export default function QuandConsulter() {
  const classes = useStyles();
  return (
    <TypoBlock>
      <Typography variant="h1">
        Quand Consulter?
      </Typography>
      <Grid
        container 
        direction="row"
        // justify="center"
        alignItems="stretch"
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <FingerprintIcon className={classes.icn}/>
            Manque de soin
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <HourglassEmptyIcon className={classes.icn}/>
            Lenteur
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <HelpIcon className={classes.icn}/>
            Difficultés d'apprentissage
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <FlashOnIcon className={classes.icn}/>
            Douleurs
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <NewReleasesIcon className={classes.icn}/>
            Refus d'écrire
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <VisibilityOffIcon className={classes.icn}/>
            Illisibilité
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <GestureIcon className={classes.icn}/>
            Manque de fluidité
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <FitnessCenterIcon className={classes.icn}/>
            Manque d'endurance
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <BlurOnIcon className={classes.icn}/>
            Mauvaise présentation<br/>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <AirlineSeatReclineNormalIcon className={classes.icn}/>
            Posture inadaptée
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <EditIcon className={classes.icn}/>
            Préhension difficile
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <HdrOffIcon className={classes.icn}/>
            Oubli de lettres
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body1">   
        Ces signes sont autant d'indicateurs d'un trouble de l'écriture, pour lequel il existe une prise en charge.
      </Typography>
    </TypoBlock>
  );
}
