import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import CookieConsent from "react-cookie-consent";
import GA4React from "ga-4-react";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
import MenuPrincipal from './components/MenuPrincipal';
import QuandConsulter from './components/QuandConsulter';
import Marjolaine from './components/Majolaine';
import Coordonnees from './components/Coordonnees';
import Tarifs from './components/Tarifs';
import CestQuoi from './components/CestQuoi';
// import PourQui from './components/PourQui';

import ProtocoleSanitaire from './components/ProtocoleSanitaire';
import { makeStyles } from '@material-ui/core/styles';
import Carte from './components/Carte';
import Pied from './components/Pied';

// const primary = purple[500];

const red = '#EE6C87';
const blue = '#82D2EE';
const yellow = '#F7CE74';
const black = '#283054';
const green = '#06D6A0';
const white = '#FDF5FE';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: black,
    },
    secondary: {
      main: green//[500],
    },
  },
  typography: {
    fontFamily: [
      'Josefin Sans',
      'sans-serif',
    ].join(','),
    fontWeightRegular: 300,
    fontWeightBold: 600,
    body1: {
      fontSize: '1.5em',
      letterSpacing: '0em',
      flexGrow:1,
      display: 'flex',
    },
    h1: {
      fontSize: '2em',
      fontWeight: 600,
      fontKerning: 'normal',
      letterSpacing: '-0.06em',
      //padding: 0,
      //margin:0,
    },
    h2: {
      fontSize: '1.6em',
      fontWeight: 600,
      fontKerning: 'normal',
      letterSpacing: '-0.06em',
      marginTop: '1em',
      //padding: 0,
      //margin:0,
    },
    h5:{
      fontWeight:600,
    },
    button: {
      fontWeight: 600,
      fontSize: '1em',
      paddingTop: '0.8em'
    },
  },
});
// console.log(theme);

const useStyles = makeStyles((theme) => {
  const style = {
    root: {
      'overflow-x': 'hidden',
      //flexGrow: 1,
      //margin: '0 auto',
      //padding: theme.spacing(2),
      //alignItems: 'stretch',
      paddingTop: theme.spacing(14),
      // maxWidth: 1024,
      backgroundColor: white,
      color: black,

    },
    redBlock: {
      backgroundColor: red,
      color: white,
    },
    yellowBlock: {
      backgroundColor: yellow,
    },
    blueBlock: {
      backgroundColor: blue,
    },
    greenBlock: {
      backgroundColor: green,
    },
    mapContainer: {
      borderTop: '10px solid '+black,
      backgroundColor: black,
      //borderBottom: '10px solid #6A037C',
    },
    footer: {
      //textAlign: 'center',
      padding: '2em',
      backgroundColor: black,
      color: white,
      fontWeight: 600,
      fontSize: '1rem',
      paddingBottom: '6em',
    }
  }

  //console.log(theme);
  return style;
});

const ga4react = new GA4React("G-8JZHJ8PDBJ");

function App() {
  const classes = useStyles();
  return (
      <React.Fragment> 
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* The rest of your application */}
          <MenuPrincipal/>
          <div className={classes.root}>
            <div>
              <Container maxWidth="md">
                <Marjolaine/>
              </Container>
            </div>
            <div className={classes.blueBlock}>
              <Container maxWidth="md">
                <CestQuoi/>
              </Container>
            </div>
            <div className={classes.yellowBlock}>
              <Container maxWidth="md">
                <QuandConsulter/>
              </Container>
            </div>
            {/*
            <div className={classes.yellowBlock}>
              <Container maxWidth="md">
                <PourQui/>
              </Container>
            </div>
            */}
            <div>
              <Container maxWidth="md">
                <Tarifs/>
              </Container>
            </div>
            <div className={classes.redBlock}>
              <Container maxWidth="md">
                <ProtocoleSanitaire/>
              </Container>
            </div>
            <div>
              <Container maxWidth="md">
                <Coordonnees/>
              </Container>
            </div>
            <div className={classes.mapContainer}>
              <Carte/>
            </div>
            <div className={classes.footer}>
              <Container maxWidth="md">
                <Pied/>
              </Container>
            </div>
          </div>
          <CookieConsent 
            enableDeclineButton 
            buttonText="J'accepte" 
            declineButtonText="Je refuse" 
            cookieName="mggraphoCookie"
            style={{ background: black }}
            buttonStyle={{ background: green }}
            declineButtonStyle={{ background: red, color: "#000000" }}
            onAccept={() => {
              ga4react.initialize().then((ga4) => {
                ga4.pageview('/')
                // ga4.gtag('event','pageview','path') // or your custom gtag event
              },(err) => {
                console.error(err);
              })
            }}
          >
            Ce site utilise des cookies à des fins de mesure d'audience.
          </CookieConsent>
        </ThemeProvider>
      </React.Fragment>
  );
}

export default App;
