// import React from 'react';
import { Typography } from '@material-ui/core';
import TypoBlock from './TypoBlock';

export default function ProtocoleSanitaire() {

  return (
    <TypoBlock>
      <Typography variant='h1'>Protocole Sanitaire</Typography>
      <Typography variant='body1'>
        Lavage des mains, port du masque, aération des locaux et désinfection du matériel et du mobilier après chaque séance.
      </Typography>
    </TypoBlock>
  );
}
