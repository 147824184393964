import React from 'react';
import TypoBlock from './TypoBlock';
import Typography from '@material-ui/core/Typography';

export default function CestQuoi() {
  return (
    <TypoBlock>
      <Typography variant="h1">
        Qu'est ce que la dysgraphie?
      </Typography>
      <Typography variant="body1">
        La dysgraphie est une atteinte de la qualité de l'écriture indépendante d'un déficit intellectuel ou neurologique. L'aisance, la rapidité et la lisibilité de l'écriture sont perturbées.
      </Typography>
    </TypoBlock>
  );
}
