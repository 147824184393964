import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    // padding: theme.spacing(3),
    // borderRadius: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

export default function TypoBlock({ children }) {
  const classes = useStyles();
  return(
    <div className={classes.root}>
      {children}
    </div>
  )
}