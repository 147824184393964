import React from 'react';
import { Typography } from '@material-ui/core';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import Grid from '@material-ui/core/Grid';
import TypoBlock from './TypoBlock';
import { makeStyles } from '@material-ui/core/styles';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({
  coords: {
    fontSize: '1.3rem',
  },
  nodeco: {
    textDecoration: 'none',
    color: '#000000',
    "&:hover": {
      color: "#82D2EE",
      textDecoration: 'underline'
    },
  },
  social: {
    fontSize: '1.3rem',
    textAlign: 'center',
  },
  socialLink: {
    textDecoration: 'none',
    color: '#000000',
    "&:hover": {
      color: "#666666",
      textDecoration: 'underline'
    },
  },
}));


export default function Coordonnees() {
  const classes = useStyles();

  return (
    <TypoBlock>
      <Grid
        container 
        direction="row"
        // justify="center"
        alignItems="stretch"
        // spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <Typography variant='h1'>Coordonnées</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coords}>
          <p>
            <ExploreRoundedIcon fontSize='large'/><br/>
            Marjolaine Benmoussa<br/>
            5 boulevard du Roi René<br/>
            13100 Aix-en-Provence
          </p>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coords}>
          <p>
            <PhoneRoundedIcon fontSize='large'/><br/>
            <a className={classes.nodeco} href='tel:0782389861'>07 82 38 98 61</a>
          </p>
          <p>
            <AlternateEmailRoundedIcon fontSize='large'/><br/>
            <a className={classes.nodeco} href='mailto:contact@mbgraphotherapeute.fr'>contact@mbgraphotherapeute.fr</a>
          </p>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.social}>
          <p>
          <a className={classes.socialLink} href='https://www.facebook.com/people/Marjolaine-Benmoussa-Graphothérapeute/100068888671212/' target='_blank' rel='noreferrer'><FacebookIcon fontSize='large'/></a>
          <a className={classes.socialLink} href='https://instagram.com/marjolainebenmoussa' target='_blank' rel='noreferrer'><InstagramIcon fontSize='large'/></a>
          <a className={classes.socialLink} href='https://fr.linkedin.com/in/marjolaine-benmoussa-b25458217' target='_blank' rel='noreferrer'><LinkedInIcon fontSize='large'/></a>
          </p>
        </Grid>
       
      </Grid>
    </TypoBlock>
  );
}
