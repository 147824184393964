import React from 'react';
import { MapContainer, TileLayer, Popup } from 'react-leaflet'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const style ={
    root: {
      //borderTop: '10px solid #6A037C',
      //borderBottom: '10px solid #6A037C',
    }
  }
  return style;
});

export default function Carte() {
  const classes = useStyles();
  const position = [43.5240982,5.447246002901208];
  return (
    <MapContainer className={classes.root} center={position} zoom={17} scrollWheelZoom={false} style={{height:'30em'}} zoomControl={true} closePopupOnClick={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="http://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png "
      />
      <Popup position={position} closeButton={false}> 
        <div style={{textAlign:'center'}}>
          <strong>Marjolaine Benmoussa</strong><br/>
          Graphotherapeute<br/>
          -<br/>
          <em>5, Boulevard du Roi René</em>
        </div>
      </Popup>
    </MapContainer>

  );
}
